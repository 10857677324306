import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import {
  COLOR_WHITE, COLOR_CAPTION, COLOR_ACCENT, COLOR_BLACK,
} from 'theme/colors';

// eslint-disable-next-line no-shadow
export enum FontWeights {
  fontWeightRegular = 400,
  fontWeightMedium = 500,
  fontWeightBold = 600,
}

export const getTypographyOptions = (): TypographyOptions => {
  const boldStyles = {
    '&.strong': {
      fontWeight: FontWeights.fontWeightMedium,
    },
  };

  return {
    fontFamily: 'IBM Plex Mono, sans-serif',

    allVariants: {
      color: COLOR_WHITE,
      fontWeight: FontWeights.fontWeightRegular,
      textAlign: 'left',
      fontStyle: 'normal',
    },

    h1: {
      fontSize: '72px',
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: '72px',
      letterSpacing: '-0.005em',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '24px',
      fontWeight: FontWeights.fontWeightBold,
      lineHeight: '24px',
      letterSpacing: '0px',
      minHeight: '30px',
    },
    button: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: FontWeights.fontWeightMedium,
      textTransform: 'none',
    },
    body1: {
      fontSize: '18px',
      lineHeight: '1.5',
      letterSpacing: '0.02em',
      ...boldStyles,

      '&.sans': {
        fontFamily: 'IBM Plex Sans, sans-serif',
      },

      '&.grey': {
        color: COLOR_WHITE,
        opacity: 0.7,
      },

      '&.black': {
        color: COLOR_BLACK,
      },

      '&.accent': {
        color: COLOR_ACCENT,
      },

      '&.capitalize': {
        textTransform: 'capitalize',
      },

      '&.l': {
        fontSize: '24px',
        lineHeight: '1.3',
        letterSpacing: '0px',
        textTransform: 'none',
      },

      '&.s': {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0px',
      },

      '&.xs': {
        fontSize: '12px',
        fontWeight: FontWeights.fontWeightMedium,
        lineHeight: '20px',
        letterSpacing: '0px',
      },

      '&.articleLargeLink': {
        fontSize: '20px',
        lineHeight: '32px',
        letterSpacing: '0.01em',
        textDecoration: 'underline',
        textDecorationThickness: '1.5px',
        textUnderlineOffset: '2px',
      },

      '&.articleSmall': {
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '0.02em',
      },
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-0.5px',
      color: 'inherit',
    },
    caption: {
      fontSize: '12px',
      color: COLOR_CAPTION,
    },
  };
};
