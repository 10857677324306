import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './StakingWhitelistInfo.styles';

export interface StakingWhitelistInfoProps {
  amount: number | string;
  stakePeriod: number;
  startStakeTimestamp: number;
  className?: string;
}

export const StakingWhitelistInfo: VFC<StakingWhitelistInfoProps> = ({
  className,
  stakePeriod,
  amount,
  startStakeTimestamp,
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)}>
      <Typography align="center" variant="body1" className={clsx(classes.text)}>
        In the current staking whitelist, you can stake <span>{amount} KON</span> for a term of{' '}
        <span>
          {stakePeriod} year{stakePeriod > 1 ? 's' : ''}
        </span>{' '}
        starting from{' '}
        <span>
          {new Date(startStakeTimestamp).toLocaleDateString('en-EU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </span>
      </Typography>
    </Box>
  );
};
