import { TransactionConfig } from 'web3-core';

import Web3 from 'web3';
import { BaseContract } from 'contract-types/types';

export type EstimateGasOptions = {
  contract: BaseContract;
  method: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methodArgs: Array<any>; // TODO: fix any type
  txOpts: TransactionConfig | [];
} & {
  web3Provider: Web3;
};

export const estimateGasForMethod = async ({
  contract,
  method,
  methodArgs,
  txOpts = [],
  web3Provider,
}: EstimateGasOptions): Promise<{ estimatedGas: number; gasPrice: string }> => {
  try {
    const transactionObject = contract.methods[method](...methodArgs);
    const estimatedGas = await transactionObject.estimateGas(txOpts);

    const gasPrice = await web3Provider.eth.getGasPrice();

    return { estimatedGas, gasPrice };
  } catch (error) {
    console.error('Error estimating gas:', error);
    throw error;
  }
};
