import React, {
  useState, VFC, useCallback, useEffect, useMemo,
} from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import clsx from 'clsx';
import { CompleteIconFilled } from 'theme/icons';
import { calculatorUrl } from 'config/constants';
import { useShallowSelector } from 'hooks';
import { RequestStatus, State, UserState } from 'types';
import userSelector from 'store/user/selectors';
import uiSelector from 'store/ui/selectors';
import actionTypesUser from 'store/user/actionTypes';
import actionTypesStakes from 'store/stakes/actionTypes';
import { routes } from 'appConstants';
import { useNavigate } from 'react-router-dom';
import { formatNumber, validateOnlyNumbers } from 'utils';
import { useDispatch } from 'react-redux';
import { stake } from 'store/stakes/actions';
import { useWalletConnectorContext } from 'services';
import { getTokenBalance, getWhitelistForUser } from 'store/user/actions';
import { Loader } from 'components';
import BigNumber from 'bignumber.js';
import { useStyles } from './StakePage.styles';
import { StakingWhitelistInfo } from './components/StakingWhitelistInfo';
import { getFixedReward, multiplierPerYear } from './StakePage.helper';

export const StakePage: VFC = () => {
  const classes = useStyles();
  const { address, balance, whitelist } = useShallowSelector<State, UserState>(
    userSelector.getUser,
  );
  const { walletService } = useWalletConnectorContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [amount, setAmount] = useState('');
  const [isNotEnoughTokens, setIsNotEnoughTokens] = useState(false);
  const [lockUpPeriod, setLockUpPeriod] = useState(0);

  const {
    [actionTypesUser.GET_WHITELIST_FOR_USER]: whitelistRequestStatus,
    [actionTypesUser.GET_TOKEN_BALANCE]: balanceRequestStatus,
    [actionTypesStakes.STAKE]: stakeRequestStatus,
  } = useShallowSelector(uiSelector.getUI);

  const isInfoLoading = useMemo(
    () => [balanceRequestStatus, whitelistRequestStatus].includes(RequestStatus.REQUEST),
    [whitelistRequestStatus, balanceRequestStatus],
  );

  const isStakeSuccess = useMemo(
    () => stakeRequestStatus === RequestStatus.SUCCESS,
    [stakeRequestStatus],
  );

  const isStaking = useMemo(
    () => stakeRequestStatus === RequestStatus.REQUEST,
    [stakeRequestStatus],
  );

  const isButtonDisabled = useMemo(
    () => !Number(amount) || isStaking || isNotEnoughTokens,
    [amount, isStaking, whitelist, isNotEnoughTokens],
  );

  const isWhitelist = useMemo(
    () => whitelist.enteredAt !== '0',
    [whitelist],
  );

  // const isUserChangedDefaultWhiltestConfig = useMemo(
  //   () => isWhitelist && (amount !== whitelist.amount || lockUpPeriod !== +whitelist.lockUpWL),
  //   [whitelist, amount, isWhitelist, lockUpPeriod],
  // );

  const handleInputChange = useCallback(
    (text: string) => {
      if (validateOnlyNumbers(text)) {
        setAmount(text);
        setIsNotEnoughTokens(new BigNumber(+text).isGreaterThan(+balance));
      }
    },
    [setAmount, balance, address, setIsNotEnoughTokens],
  );

  const handleStake = useCallback((amountToStake: string, stakePeriod: number) => {
    dispatch(
      stake({
        amount: amountToStake,
        lockUpPeriod: stakePeriod,
        web3Provider: walletService.Web3(),
      }),
    );
  }, []);

  useEffect(() => {
    if (!address) {
      navigate(routes.root);
      return;
    }

    if(walletService) {
      dispatch(getWhitelistForUser({ web3Provider: walletService.Web3(), address }));
      dispatch(
        getTokenBalance({ web3Provider: walletService.Web3() }),
      );
    }
  }, [address, walletService]);

  // useEffect(() => {
  //   if(!isWhitelist) {
  //     setAmount('');
  //     setLockUpPeriod(0);
  //     return;
  //   }

  //   handleInputChange(whitelist.amount);
  //   setLockUpPeriod(+whitelist.lockUpWL);
  // }, [whitelist, isWhitelist]);

  useEffect(() => {
    if(isStakeSuccess) {
      navigate(routes.root);
      dispatch({ type: 'RESET_UI' });
    }
  }, [isStakeSuccess]);

  useEffect(() => {
    setIsNotEnoughTokens(new BigNumber(+amount).isGreaterThan(+balance));
  }, [address, balance]);

  return (
    <Container maxWidth="lg" className={classes.root}>
      {isInfoLoading && <Loader />}
      <Typography variant="h1" className={classes.title}>
        Staking
      </Typography>
      {isWhitelist ? (
        <StakingWhitelistInfo
          className={classes.whitelist}
          stakePeriod={+whitelist.lockUpWL + 1}
          amount={whitelist.amount}
          startStakeTimestamp={+whitelist.enteredAt}
        />
      ) : (
        <Typography className={clsx(classes.subtitle, 's')}>
          An All-In-One Solution Allowing Projects To Get Connected With Retail Investors
        </Typography>
      )}
      {isWhitelist && (
        <Typography
          className={classes.warningAboutWl}
          align="center"
        >
          Your whitelist has expired
        </Typography>
      )}
      <Box className={classes.section}>
        <Typography className={classes.amount} variant="caption">
          Amount
        </Typography>
        <TextField
          placeholder="200000"
          value={amount}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        {isNotEnoughTokens && (
          <Typography className={clsx(classes.warning, 's sans')}>
            Not enough tokens for staking. <br /> Your balance: {formatNumber(+balance, 18)}
          </Typography>
        )}
        <FormControl className={classes.checkboxes} component="fieldset">
          <RadioGroup
            className={classes.checkboxesInner}
            onChange={(e) => setLockUpPeriod(Number(e.target.value))}
            defaultValue={0}
            value={lockUpPeriod.toString()}
          >
            <FormControlLabel
              control={(
                <Radio
                  value="0"
                  className={classes.checkboxButton}
                  color="default"
                  checkedIcon={<CompleteIconFilled />}
                />
              )}
              label="1 year"
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={(
                <Radio
                  value="1"
                  className={classes.checkboxButton}
                  color="default"
                  checkedIcon={<CompleteIconFilled />}
                />
              )}
              label="2 years"
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={(
                <Radio
                  value="2"
                  className={classes.checkboxButton}
                  color="default"
                  checkedIcon={<CompleteIconFilled />}
                />
              )}
              label="3 years"
              className={classes.checkboxLabel}
            />
          </RadioGroup>
        </FormControl>
        <Box className={classes.info}>
          <Typography align="center" variant="h2" className={classes.reward}>
            Fixed reward
          </Typography>
          <Typography noWrap align="center" className={clsx(classes.rewardValue, 'sans')}>
            + {formatNumber(+getFixedReward(Number(amount), lockUpPeriod))}{' '}
            <span>KON</span>
          </Typography>
          <Typography align="center" variant="body1" className={clsx(classes.percent, 'sans')}>
            {multiplierPerYear[lockUpPeriod] * 100}%
          </Typography>
        </Box>
        <Box className={classes.center}>
          <Button color="primary" variant="outlined" target="_blank" href={calculatorUrl}>
            Rewards calculator
          </Button>
        </Box>
      </Box>
      <Box className={classes.center}>
        <Button
          disabled={isButtonDisabled}
          onClick={() => handleStake(amount, lockUpPeriod)}
          size="large"
          color="secondary"
        >
          Stake
        </Button>
      </Box>
    </Container>
  );
};
