const GET_STAKES_DATA: 'GET_STAKES_DATA' = 'GET_STAKES_DATA';
const STAKE: 'STAKE' = 'STAKE';
const UNSTAKE: 'UNSTAKE' = 'UNSTAKE';
const APPROVE: 'APPROVE' = 'APPROVE';
const CLAIM: 'CLAIM' = 'CLAIM';

export default {
  GET_STAKES_DATA,
  STAKE,
  UNSTAKE,
  APPROVE,
  CLAIM,
};
