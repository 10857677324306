/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chains, IConnectWallet, IContracts } from 'types';
import { erc20Abi, stakingAbi } from './abi';
import { isMainnet } from './constants';

export const chains: {
  [key: string]: {
    name: string;
    chainId: number;
    provider: {
      [key: string]: any;
    };
    img?: any;
  };
} = {
  Binance: {
    name: isMainnet ? 'Binance-Smart-Chain' : 'Binance-Smart-Chain Testnet',
    chainId: isMainnet ? 56 : 97,
    provider: {
      MetaMask: { name: 'MetaMask' },
    },
  },
};

export const connectWallet = (newChainName: string): IConnectWallet => {
  const chain = chains[newChainName];
  return {
    network: {
      chainName: chain.name,
      chainID: chain.chainId,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

// eslint-disable-next-line no-shadow
export enum ContractsNames {
  token = 'token',
  staking = 'staking',
}

export type IContractsNames = keyof typeof ContractsNames;

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.token]: {
      mainnet: {
        address: {
          [Chains.binance]: '0x17A1F0820269C227a4ceaA024e25FF7446A37F8F',
        },
        abi: erc20Abi,
      },
      testnet: {
        address: {
          [Chains.binance]: '0x939eD6a62bB36EDBFaF469d2FfABB7318Ba8Db10',
        },
        abi: erc20Abi,
      },
    },
    [ContractsNames.staking]: {
      mainnet: {
        abi: stakingAbi,
        address: {
          [Chains.binance]: '0x35FCFAFbEBf63eBfa2ED5813c419e03808e9DB98',
        },
      },
      testnet: {
        abi: stakingAbi,
        address: {
          [Chains.binance]: '0x22e78324Bc75f2Edc75e136Ed9A8c9c7bA0BB2a7',
        },
      },
    },
  },
};
