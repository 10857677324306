import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_WHITE } from 'theme/colors';
import { FontWeights } from 'theme/Typography';

import { getFormatMedia } from 'theme/utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
      padding: theme.spacing(2.5, 3.5),
      border: `1px solid ${COLOR_WHITE}`,
      borderRadius: 14,
      maxWidth: 553,
      margin: '0 auto',
      textAlign: 'center',
    },

    text: {
      '& span': {
        fontWeight: FontWeights.fontWeightBold,
      },
    },
  });
});
