import { Theme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';

export const getMuiContainer = (theme: Theme): Overrides['MuiContainer'] => ({
  root: {
    maxWidth: 1385,
    padding: theme.spacing(0, 2),
  },
});

export const getMuiContainerDefaultProps = (): ComponentsProps['MuiContainer'] => ({ maxWidth: false });
