import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWhitelist, UserState } from 'types';

const initialState: UserState = {
  address: '',
  balance: '0',
  walletProvider: '',
  whitelist: {
    enteredAt: '0',
    amount: '',
    lockUpWL: '',
  },
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),
    updateWhitelistInfo: (state, action: PayloadAction<IWhitelist>) => ({
      ...state,
      whitelist: {
        ...action.payload,
      },
    }),
    disconnectWalletState: () => ({
      ...initialState,
    }),
  },
});

export const { disconnectWalletState, updateUserState, updateWhitelistInfo } = userReducer.actions;

export default userReducer.reducer;
