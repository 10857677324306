import React, { useMemo, VFC } from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { WarningBigIcon } from 'theme/icons';
import { formatNumber } from 'utils';
import { ICurrentRewards } from 'types';
import { useStyles } from './WithdrawModal.styles';

export interface WithdrawModalProps extends ModalProps {
  onWithdraw: () => void;
  fixedPenalty: number;
  currentRewards: ICurrentRewards;
}

export const WithdrawModal: VFC<WithdrawModalProps> = (props) => {
  const {
    open, onClose, onWithdraw, fixedPenalty, currentRewards,
  } = props;
  const classes = useStyles();

  const withholdingAmount = useMemo(
    () => fixedPenalty - +currentRewards.fixedAmount,
    [fixedPenalty, currentRewards],
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.root}
      headerClassName={classes.header}
      {...props}
    >
      <WarningBigIcon className={classes.icon} />
      <Typography align="center" className={clsx(classes.desc)} variant="body1">
        You want to withdraw before the term end
      </Typography>
      <Typography align="center" className={clsx(classes.amount, 'sans')} variant="body1">
        {withholdingAmount < 0 ? 0 : formatNumber(withholdingAmount)} <span>KON</span>{' '}
        {+currentRewards.variablePartUSDT > 0 && (
          <>
            and {formatNumber(+currentRewards.variablePartUSDT)} <span>USDT</span>
          </>
        )}
      </Typography>
      <Typography
        align="center"
        className={clsx(classes.subtitle, 'articleSmall sans black')}
        variant="body1"
      >
        Token withholding amount
      </Typography>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={() => {
          onWithdraw();
          onClose();
        }}
      >
        Ok
      </Button>
    </Modal>
  );
};
