/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { theme } from 'theme';
import { Layout, Routes } from 'containers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import {
  Chains, State, UserState, WalletProviders,
} from 'types';
import { useWalletConnectorContext } from 'services';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <CssBaseline />
        <ToastContainer autoClose={4000} hideProgressBar position="bottom-right" closeButton={false} />
        <Layout>
          <Routes />
        </Layout>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
