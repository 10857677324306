/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled, Theme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { COLOR_ACCENT } from 'theme/colors';

export const getMuiRadio = (theme: Theme): Overrides['MuiRadio'] => ({
  root: {
    padding: 0,
    height: 24,
    width: 24,
    color: COLOR_ACCENT,
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    background: 'rgba(255, 255, 255, 0.05)',
    '&:hover': {
      borderColor: COLOR_ACCENT,
      background: 'rgba(255, 255, 255, 0.05)',
    },
    '&.Mui-disabled': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
});

export const getMuiRadioDefaultProps = (): ComponentsProps['MuiRadio'] => ({
  disableFocusRipple: true,
  disableRipple: true,
  disableTouchRipple: true,
  icon: '',
});
