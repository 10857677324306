import { UrlObject } from 'url';

import React, { FC } from 'react';

import { Box } from '@material-ui/core';

import { Header, Modals } from 'containers';
import { useStyles } from './Layout.styles';

export interface LayoutProps {
  route?: UrlObject | string;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Header />
        <Modals />
        {children}
      </Box>
    </Box>
  );
};
