import React, { VFC } from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './DisconnectModal.styles';

interface DisconnectModalProps extends ModalProps {
  disconnectWallet?: () => void;
  text?: string;
  buttonText?: string;
}

export const DisconnectModal: VFC<DisconnectModalProps> = (props) => {
  const {
    open,
    onClose,
    disconnectWallet,
    text = "You won't be able to stake or view your current stakes",
    buttonText = 'Disconnect',
  } = props;
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.root} {...props}>
      <Typography className={clsx(classes.desc, 's')} variant="body1">
        {text}
      </Typography>
      <Button className={classes.button} variant="outlined" onClick={disconnectWallet || onClose}>
        {buttonText}
      </Button>
    </Modal>
  );
};
