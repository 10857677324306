import React, { useCallback, VFC } from 'react';

import { StakeCard } from 'components/StakeCard';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { IStake, RewardTokenSymbol } from 'types';
import { useDispatch } from 'react-redux';
import { claim, unstake } from 'store/stakes/actions';
import { useWalletConnectorContext } from 'services';
import { StakeCardSkeleton } from 'components/StakeCardSkeleton';
import { useStyles } from './StakeCards.style';

interface IStakeCardsProps {
  stakes: IStake[];
  rewardTokenSymbol: RewardTokenSymbol;
  className?: string;
  isStakesLoading?: boolean;
}

const StakeCards: VFC<IStakeCardsProps> = ({
  className,
  stakes,
  rewardTokenSymbol,
  isStakesLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const onWithdraw = useCallback((stakeIndex: string) => {
    dispatch(unstake({ stakeIndex, web3Provider: walletService.Web3() }));
  }, []);

  const onClaim = useCallback((stakeIndex: string) => {
    dispatch(claim({ stakeIndex, web3Provider: walletService.Web3() }));
  }, []);

  return (
    <Box className={clsx(classes.root, className)}>
      {stakes.length > 0 && (
        <Box className={clsx(classes.header)}>
          <Typography variant="body1" className="grey articleSmall sans">
            Term
          </Typography>
          <Typography variant="body1" className="grey articleSmall sans">
            Total fixed reward
          </Typography>
          <Typography variant="body1" className="grey articleSmall sans">
            Final return
          </Typography>
          <Typography variant="body1" className="grey articleSmall sans">
            Current fixed reward
          </Typography>
        </Box>
      )}
      {isStakesLoading ? (
        <>
          <StakeCardSkeleton />
          <StakeCardSkeleton />
          <StakeCardSkeleton />
        </>
      ) : (
        stakes.map((stake) => (
          <StakeCard
            key={stake.stakeIndex}
            onWithdraw={() => onWithdraw(stake.stakeIndex)}
            onClaim={() => onClaim(stake.stakeIndex)}
            stake={stake}
            rewardTokenSymbol={rewardTokenSymbol}
          />
        ))
      )}
    </Box>
  );
};

export default StakeCards;
