import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BORDER, COLOR_CARD_BG } from 'theme/colors';

import { getFormatMedia } from 'theme/utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {},
      background: COLOR_CARD_BG,
      border: `1px solid ${COLOR_BORDER}`,
      padding: theme.spacing(4, 4),
      borderRadius: 20,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 3, 1),
      },

      display: 'grid',
      gap: 10,
      alignItems: 'center',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 220px',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 20,
        columnGap: 30,
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
  });
});
