import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import {
  COLOR_ERROR,
  COLOR_MINT_100,
  COLOR_GREY_SEMI_40,
  COLOR_BORDER,
  COLOR_WHITE,
} from 'theme/colors';

export const getMuiInputBase = (theme: Theme): Overrides['MuiInputBase'] => ({
  root: {
    background: COLOR_GREY_SEMI_40,
    color: COLOR_WHITE,
  },
  input: {
    height: theme.spacing(7.5),
    borderRadius: theme.spacing(1),
    padding: '12px 18px !important',
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: 32,
    textAlign: 'center',
    color: COLOR_MINT_100,
    '& legend': {
      maxWidth: 0,
    },
  },
});

export const getMuiOutlinedInput = (
  theme: Theme,
): Overrides['MuiOutlinedInput'] => ({
  root: {
    height: theme.spacing(7),
    color: COLOR_MINT_100,
    borderRadius: theme.spacing(1),
    '& legend': {
      maxWidth: 0,
    },
    '&$focused:not(&$error) input': {
      color: COLOR_WHITE,
    },
    '&$focused:not(&$error) svg': {
      '& > *': {
        fill: COLOR_WHITE,
      },
    },
    '&$focused:not(&$error) fieldset': {
      borderWidth: 1,
      borderColor: COLOR_BORDER,
    },
    '&:not(&$disabled):hover fieldset, &$error fieldset': {
      borderWidth: 1,
      borderColor: COLOR_BORDER,
    },
    '&$error fieldset': {
      borderWidth: 1,
      borderColor: COLOR_ERROR,
    },
    '&$error svg': {
      '& > *': {
        fill: COLOR_ERROR,
      },
    },
    '&$disabled fieldset': {
      borderWidth: 1,
      // backgroundColor: theme.palette.type === 'dark' ? COLOR_BLACK_1 : COLOR_COLOR_GREY_3,
    },
    '&$disabled input': {
      borderWidth: 1,
      // color: theme.palette.type === 'dark' ? COLOR_BLACK_4 : COLOR_COLOR_GREY_2,
    },
    '& fieldset': {
      borderWidth: 1,
      borderColor: COLOR_BORDER,
    },
    '& svg': {
      zIndex: 99,
    },
  },
});

export const getMuiTextField = (): Overrides['MuiTextField'] => ({
  root: {
    width: '100%',
    '&.noLabel': {
      width: '100%',
      '& > div': {
        '& > div': {
          padding: '12px 18px 12px 18px !important',
        },
      },
    },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  variant: 'outlined',
  SelectProps: {
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    },
  },
});

export const getMuiFilledInputDefaultProps = (): ComponentsProps['MuiFilledInput'] => ({
  disableUnderline: true,
});
