import { LinkProps } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

import {
  COLOR_BORDER,
  COLOR_WHITE,
} from 'theme/colors';

export const getMuiIconButton = (): Overrides['MuiIconButton'] => ({
  root: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    transition: '300ms ease-out',
    '&:hover, &:active': {
      background: COLOR_BORDER,
    },
    '&$disabled': {
      opacity: 0.5,
      color: COLOR_BORDER,
      backgroundColor: 'transparent',
    },
  },
  colorPrimary: {
    color: COLOR_WHITE,
    width: 40,
    height: 40,
    '&:hover, &:active': {
      '& > *': {
        '& > *': {
          '& > *': {
            fill: COLOR_WHITE,
          },
        },
      },
    },
    '&$disabled': {
      opacity: 0.25,
      backgroundColor: COLOR_WHITE,
    },
    '& > *': {
      '& > *': {
        '& > *': {
          fill: COLOR_WHITE,
        },
      },
    },
  },
  colorSecondary: {
    background: 'transparent',
    width: 24,
    height: 24,
    '&:hover': {
      borderWidth: 0,
      background: 'transparent',
      '& > *': {
        '& > *': {
          '& > *': {
            fill: COLOR_BORDER,
          },
        },
      },
    },
    '&:active': {
      borderWidth: 0,
    },
    '&$disabled': {
      opacity: 0.5,
      borderWidth: 0,
    },
    '& > *': {
      '& > *': {
        '& > *': {
          fill: COLOR_BORDER,
        },
      },
    },
  },
});

export const getMuiIconButtonDefaultProps = (): LinkProps => ({ });
