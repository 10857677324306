import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
// @ts-ignore can't find module for some reason
import ibmPlexMono from '@fontsource/ibm-plex-mono';
// @ts-expect-error
import ibmPlexSans from '@fontsource/ibm-plex-sans';

export const getMuiCssBaseline = (theme: Theme): Overrides['MuiCssBaseline'] => ({
  '@global': {
    '@font-face': [ibmPlexMono, ibmPlexSans],
    html: {
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
      boxSizing: 'border-box',
    },
    body: {
      margin: theme.spacing(0),
      minHeight: '100vh',
    },
  },
});

export const getMuiCssBaselineDefaultProps = (): ComponentsProps['MuiCssBaseline'] => ({});
