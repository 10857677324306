import { createTheme } from '@material-ui/core';
import { getMuiButton, getMuiButtonDefaultProps } from './Button';

import { getMuiContainer, getMuiContainerDefaultProps } from './Container';
import { getMuiCssBaseline, getMuiCssBaselineDefaultProps } from './CssBaseline';
import { getMuiDialogDefaultProps, getMuiDialogProps } from './Dialog';
import { getMuiGrid, breakpointOptions, getMuiGridDefaultProps } from './Grid';
import { getMuiLinkDefaultProps, getMuiLinkOverride } from './Link';
import { getMuiSwitch, getMuiSwitchDefaultProps } from './Switch/Switch.theme';
import { getTypographyOptions } from './Typography';
import { COLOR_ERROR } from './colors';
import { getMuiIconButton, getMuiIconButtonDefaultProps } from './IconButton';
import {
  getMuiMenu,
  getMuiPaper,
  getMuiMenuItem,
  getMuiListItem,
  getMuiSelect,
} from './Select/Select.theme';
import {
  getMuiFilledInputDefaultProps,
  getMuiInputBase,
  getMuiOutlinedInput,
  getMuiTextField,
  getMuiTextFieldDefaultProps,
} from './TextField';
import { getMuiRadioDefaultProps, getMuiRadio } from './Radio';
import { getMuiAccordion, getMuiAccordionDetails } from './Accordion/Accordion.theme';

// eslint-disable-next-line import/no-mutable-exports
export let theme = createTheme({

  palette: {
    type: 'dark',
    error: {
      main: COLOR_ERROR,
    },
  },
  typography: getTypographyOptions(),
  breakpoints: breakpointOptions,
  spacing: 8,

});

theme = createTheme(theme, {
  props: {
    MuiCssBaseline: getMuiCssBaselineDefaultProps(),
    MuiLink: getMuiLinkDefaultProps(),
    MuiButtonBase: { disableRipple: true },
    MuiContainer: getMuiContainerDefaultProps(),
    MuiGrid: getMuiGridDefaultProps(),
    MuiTypography: getTypographyOptions(),
    MuiButton: getMuiButtonDefaultProps(),
    MuiSwitch: getMuiSwitchDefaultProps(),
    MuiDialog: getMuiDialogDefaultProps(),
    MuiTextField: getMuiTextFieldDefaultProps(),
    MuiRadio: getMuiRadioDefaultProps(),
    MuiIconButton: getMuiIconButtonDefaultProps(),
    MuiFilledInput: getMuiFilledInputDefaultProps(),
  },
  overrides: {
    MuiCssBaseline: getMuiCssBaseline(theme),
    MuiLink: getMuiLinkOverride(theme),
    MuiContainer: getMuiContainer(theme),
    MuiGrid: getMuiGrid(theme),
    MuiButton: getMuiButton(theme),
    MuiSwitch: getMuiSwitch(theme),
    MuiDialog: getMuiDialogProps(theme),
    MuiInputBase: getMuiInputBase(theme),
    MuiOutlinedInput: getMuiOutlinedInput(theme),
    MuiTextField: getMuiTextField(),
    MuiRadio: getMuiRadio(theme),
    MuiIconButton: getMuiIconButton(),
    MuiMenu: getMuiMenu(theme),
    MuiPaper: getMuiPaper(),
    MuiMenuItem: getMuiMenuItem(),
    MuiListItem: getMuiListItem(),
    MuiAccordion: getMuiAccordion(theme),
    MuiAccordionDetails: getMuiAccordionDetails(theme),
    MuiSelect: getMuiSelect(),
  },
});
