import React, { VFC } from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { MetamaskLogo } from 'theme/icons';
import { useStyles } from './ConnectWalletModal.styles';

export interface ConnectWalletModalProps extends ModalProps {
  connectWallet?: () => void;
}

export const ConnectWalletModal: VFC<ConnectWalletModalProps> = (props) => {
  const {
    open,
    onClose,
    connectWallet,
  } = props;
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} customTitle="Connect a Wallet" className={classes.root} {...props}>
      <Typography className={clsx(classes.desc, 's')} variant="body1">
        Please connect your wallet
      </Typography>
      <Button size="large" className={classes.button} variant="outlined" onClick={connectWallet}>
        <MetamaskLogo />
      </Button>
    </Modal>
  );
};
