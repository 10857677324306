import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BLACK } from 'theme/colors';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      width: '100%',
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    modalHeader: {
      ...flexHelper('space-between', 'flex-start'),
      marginBottom: theme.spacing(1),
    },
    modalTitle: {
      color: COLOR_BLACK,
      paddingRight: theme.spacing(2),
    },
  });
});
