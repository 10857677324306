import { createAction } from '@reduxjs/toolkit';
import {
  GetStakesReq,
  StakeReq,
  UnstakeReq,
  ApproveReq,
  ClaimReq,
} from 'types/requests';
import actionTypes from './actionTypes';

export const requestStakesData = createAction<GetStakesReq>(actionTypes.GET_STAKES_DATA);
export const stake = createAction<StakeReq>(actionTypes.STAKE);
export const unstake = createAction<UnstakeReq>(actionTypes.UNSTAKE);
export const approve = createAction<ApproveReq>(actionTypes.APPROVE);
export const claim = createAction<ClaimReq>(actionTypes.CLAIM);
