import React, { useCallback, useState, VFC } from 'react';
import {
  Box, Button, Container, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo.png';
import { routes } from 'appConstants';
import { DisconnectModal } from 'components';
import { useWalletConnectorContext } from 'services';
import { useShallowSelector } from 'hooks';
import { State, UserState } from 'types';
import userSelector from 'store/user/selectors';
import { useDispatch } from 'react-redux';
import { updateWalletConnectModal } from 'store/walletConnectModal/reducer';
import { useStyles } from './Header.styles';

export interface HeaderProps {
  className?: string;
}

export const Header: VFC<HeaderProps> = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { disconnect } = useWalletConnectorContext();

  const { address } = useShallowSelector<State, UserState>(userSelector.getUser);

  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);

  const handleOpenConnectWalletModal = useCallback(() => {
    dispatch(updateWalletConnectModal({ isOpen: true }));
  }, []);

  const disconnectModal = useCallback(() => {
    setIsDisconnectModalOpen(!isDisconnectModalOpen);
  }, [isDisconnectModalOpen]);

  const disconnectWallet = useCallback(() => {
    disconnect();
    setIsDisconnectModalOpen(false);
  }, []);

  const classes = useStyles();

  return (
    <Container className={clsx(classes.root, className)}>
      <Box className={classes.headerContainer}>
        <Box className={classes.header}>
          <Box className={classes.headerLogo} onClick={() => navigate(routes.root)}>
            <img style={{ cursor: 'pointer' }} src={Logo} alt="logo" />
          </Box>
          <Typography variant="body1" className={classes.headerText}>Powered by KON Token, Fueling the Ecosystem</Typography>
          {address ? (
            <>
              <Button
                className={clsx(classes.address, classes.headerButton)}
                onClick={disconnectModal}
                variant="outlined"
                size="medium"
              >
                {address}
              </Button>
            </>
          ) : (
            <Button
              onClick={handleOpenConnectWalletModal}
              className={clsx(classes.headerButton)}
              variant="outlined"
            >
              Connect wallet
            </Button>
          )}
        </Box>
      </Box>
      {isDisconnectModalOpen && (
        <DisconnectModal
          open={isDisconnectModalOpen}
          onClose={disconnectModal}
          disconnectWallet={disconnectWallet}
          customTitle="Disconnect a Wallet?"
        />
      )}
    </Container>
  );
};
