import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { COLOR_BLACK } from 'theme/colors';
import { FontWeights } from 'theme/Typography';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    desc: {
      color: COLOR_BLACK,
      margin: theme.spacing(0, 'auto', 1),
      maxWidth: 340,
    },
    header: {
      marginBottom: theme.spacing(-3.5),
    },
    icon: {
      ...flexHelper(),
      width: '100%',
    },
    amount: {
      color: COLOR_BLACK,
      fontWeight: FontWeights.fontWeightMedium,
      fontSize: 32,
      maxWidth: '80%',
      margin: theme.spacing(0, 'auto', 0.5),
      '& span': {
        fontSize: 24,
        fontWeight: FontWeights.fontWeightRegular,
      },
    },
    subtitle: {
      marginBottom: theme.spacing(2),
    },
    button: {
      padding: theme.spacing(2, 4),
      color: COLOR_BLACK,
      width: 200,
      margin: '0 auto',
    },
  });
});
