import { BigNumber } from 'bignumber.js';
/* eslint-disable max-len */
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { isMainnet } from 'config/constants';
import { toDecimals, fromDecimals, estimateGasForMethod } from 'utils';
import actionTypes from 'store/stakes/actionTypes';
import userSelector from 'store/user/selectors';
import { Chains } from 'types';
import { toast } from 'react-toastify';
import { ReactText } from 'react';
import { updateUserState } from 'store/user/reducer';
import { stake } from '../actions';
import { approveSaga } from './approveSaga';
import { getStakesDataSaga } from './getStakesData';

function* stakeSaga({
  type, payload: {
    amount,
    web3Provider,
    lockUpPeriod,
  },
}: ReturnType<typeof stake>) {
  yield put(apiActions.request(type));
  const {
    abi: stakingAbi, address: stakingContractAddress,
  } = contractsConfig.contracts[ContractsNames.staking][isMainnet ? 'mainnet' : 'testnet'];

  const {
    abi: tokenAbi,
    address: tokenAddress,
  } = contractsConfig.contracts[ContractsNames.token][isMainnet ? 'mainnet' : 'testnet'];

  const myAddress = yield select(userSelector.getProp('address'));
  const balance = yield select(userSelector.getProp('balance'));

  const amountWithDecimals = toDecimals(amount);
  let toastId: ReactText;
  try {
    const tokenContract = yield (new web3Provider.eth.Contract(tokenAbi, tokenAddress[Chains.binance]));
    const stakingContract = yield (new web3Provider.eth.Contract(stakingAbi, stakingContractAddress[Chains.binance]));

    const allowance = yield call(tokenContract.methods.allowance(myAddress, stakingContractAddress[Chains.binance]).call);
    const maxPoolSize = yield call(stakingContract.methods.maxPool().call);
    const stakedSum = yield call(stakingContract.methods.stakedSum().call);

    const poolRemaining = +fromDecimals(maxPoolSize) - +fromDecimals(stakedSum);

    if(+amount > poolRemaining) {
      toast.error('You have exceeded the maximum pool size. Please stake a smaller amount!');
      yield put(apiActions.error(type, 'Max pool size'));
      return;
    }

    toastId = toast.loading('Staking your tokens');
    if (new BigNumber(fromDecimals(allowance)).isLessThan(amount)) {
      toast.update(toastId, {
        render: 'Approving your tokens...',
        isLoading: true,
      });
      yield call(approveSaga, {
        type: actionTypes.APPROVE,
        payload: {
          web3Provider,
          amount,
          spender: stakingContractAddress[Chains.binance],
        },
      });
    }

    toast.update(toastId, {
      render: 'Staking your tokens...',
      isLoading: true,
    });

    const opts = yield call(estimateGasForMethod, {
      web3Provider,
      contract: stakingContract,
      method: 'deposit',
      txOpts: { from: myAddress },
      methodArgs: [amountWithDecimals, lockUpPeriod],
    });

    yield call(
      stakingContract.methods.deposit(amountWithDecimals, lockUpPeriod).send,
      {
        from: myAddress,
        gas: opts.estimatedGas,
        gasPrice: opts.gasPrice,
      },
    );

    toast.update(toastId, {
      render: 'Tokens are staked!',
      type: 'success',
      isLoading: false,
      closeOnClick: true,
    });
    yield put(updateUserState({ balance: (+balance - +amount).toString() }));

    if(window.location.pathname === '/') {
      yield call(getStakesDataSaga, {
        type: actionTypes.GET_STAKES_DATA,
        payload: {
          web3Provider,
        },
      });
    }

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
    toast.update(toastId, {
      render: 'Something went wrong!',
      type: 'error',
      isLoading: false,
      closeOnClick: true,
    });
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE, stakeSaga);
}
