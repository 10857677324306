import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_CARD_BG, COLOR_BORDER } from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(9),
  },

  title: {
    marginBottom: theme.spacing(1.5),
  },

  subtitle: {
    maxWidth: 520,
  },

  section: {
    background: COLOR_CARD_BG,
    borderRadius: 20,
    border: `1px solid ${COLOR_BORDER}`,
    padding: theme.spacing(5, 1),
    marginTop: theme.spacing(7),
    ...flexHelper(),
  },

  addStake: {
    marginTop: theme.spacing(3),
  },
}));
