/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';

import { Subscription } from 'rxjs';
import {
  Chains,
  State,
  UserState,
  WalletProviders,
} from 'types';
import { disconnectWalletState, updateUserState } from 'store/user/reducer';
import { useDispatch } from 'react-redux';
import userSelector from 'store/user/selectors';
import { useShallowSelector } from 'hooks';
import { chains } from 'config';
import { WalletService } from '../walletService';

declare global {
  interface Window {
    ethereum: unknown;
  }
}

interface IContextValue {
  connect: (provider: WalletProviders, chain: Chains) => Promise<void>;
  disconnect: () => void;
  walletService: WalletService;
}

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC = ({ children }) => {
  const [currentSubsriber, setCurrentSubsciber] = useState<Subscription>();
  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const {
    address,
    walletProvider,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    WalletConnect.resetConnect();
    currentSubsriber?.unsubscribe();
    setCurrentSubsciber(null);
  }, [WalletConnect, currentSubsriber, dispatch]);

  const subscriberSuccess = useCallback((data: any) => {
    if (data.name === 'accountsChanged') {
      toast.info(
        `Wallet changed: ${data.address.slice(0, 8)}...${data.address.slice(-8)}`,
      );
      dispatch(updateUserState({ address: data.address }));
    }
  }, [WalletConnect, dispatch]);

  const subscriberError = useCallback(
    (err: any) => {
      console.error(err);
      if (err.code === 4) {
        toast.error(
          `You changed to wrong network. Please choose ${chains['Binance'].name}`,
        );
        disconnect();
      }
    },
    [disconnect],
  );

  const connect = useCallback(
    async (provider: WalletProviders, chain: Chains) => {
      if(!window.ethereum) {
        // metamask doesn't installed,
        // redirect to download MM or open MM on mobile
        window.open(`https://metamask.app.link/dapp/${window.location.hostname + window.location.pathname}/`);
      }

      const connected = await WalletConnect.initWalletConnect(provider, chain);
      if (connected) {
        try {
          if(!currentSubsriber) {
            const sub = WalletConnect.eventSubscribe().subscribe(
              subscriberSuccess,
              subscriberError,
            );
            setCurrentSubsciber(sub);
          }

          const accountInfo: any = await WalletConnect.getAccount();

          if (accountInfo.address) {
            toast.success(
              `Wallet connected: ${accountInfo.address.slice(0, 8)}...${accountInfo.address.slice(-8)}`,
            );
            dispatch(
              updateUserState({ walletProvider: accountInfo.type, address: accountInfo.address }),
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    // eslint-disable-next-line max-len
    [WalletConnect, address, dispatch, subscriberError, subscriberSuccess, currentSubsriber],
  );

  useEffect(() => {
    // connect user if he connected previously
    if (walletProvider && connect) {
      connect(WalletProviders.metamask, Chains.binance);
    }
  }, []);

  return (
    <Web3Context.Provider value={{ connect, disconnect, walletService: WalletConnect }}>
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
