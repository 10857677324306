import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const ChevronDown: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="24" height="23" viewBox="0 0 24 23" fill="none" {...props}>
    <path
      d="M5 14.375L12 7.66667L19 14.375"
      stroke="#F2F2F2"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSVGIcon>
);
