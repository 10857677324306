/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WalletConnectModalState } from 'types';

const initialState: WalletConnectModalState = {
  isOpen: false,
};

export const walletConnectModalReducer = createSlice({
  name: 'successModal',
  initialState,
  reducers: {
    updateWalletConnectModal: (state, action: PayloadAction<Partial<WalletConnectModalState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateWalletConnectModal } = walletConnectModalReducer.actions;

export default walletConnectModalReducer.reducer;
