import { makeStyles } from '@material-ui/core';
import { COLOR_BG_DARK_7 } from 'theme/colors';

export const useStyles = makeStyles({
  loaderModal: {
    background: 'transparent',
  },
  root: {
    position: 'fixed',
    background: COLOR_BG_DARK_7,
    opacity: 0.8,
    top: 0,
    left: 0,
    zIndex: 9999999999,
    width: '100%',
    height: '100%',
  },
});
