import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { COLOR_BLACK } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    desc: {
      textAlign: 'left',
      color: COLOR_BLACK,
      marginBottom: theme.spacing(3),
      maxWidth: 375,
    },
    button: {
      padding: theme.spacing(2, 4),
      color: COLOR_BLACK,
      width: '80%',
      [formatMedia.BREAKPOINT_TABLET]: {
        width: '199px',
      },
    },
  });
});
