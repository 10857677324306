import React, {
  useCallback, useMemo, useState, VFC,
} from 'react';

import {
  Box, IconButton, Button, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { ChevronDown, WarningIcon } from 'theme/icons';
import { calculatorUrl } from 'config/constants';
import { useWindowState } from 'hooks';
import { InfoModal } from 'components/InfoModal';
import { WithdrawModal } from 'components/WithdrawModal';
import { IStake, RewardTokenSymbol } from 'types';
import { getFixedReward } from 'pages/StakePage/StakePage.helper';
import { formatNumber } from 'utils';
import { useStyles } from './StakeCard.styles';
import {
  dateConfig,
  MS_IN_YEAR,
  secondaryRewardTokenSymbol,
  nextClaimInfo,
} from './StakeCard.helper';

export interface StakeCardProps {
  stake: IStake;
  rewardTokenSymbol: RewardTokenSymbol;
  onWithdraw: () => void;
  onClaim: () => void;
  className?: string;
}

export const StakeCard: VFC<StakeCardProps> = ({
  className,
  onWithdraw,
  onClaim,
  rewardTokenSymbol,
  stake: {
    term, deposit, currentRewards, enteredAt, countHarvest, claimShedule,
  },
}) => {
  const [isCardOpen, setIsCardOpen] = useState(true);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const classes = useStyles({ isOpen: isCardOpen });
  const { width } = useWindowState();

  const finalReturn = useMemo(
    () => +currentRewards.fixedAmount + +currentRewards.variablePartKON + +deposit,
    [currentRewards],
  );

  const msToNextClaim = useMemo(() => {
    const data = nextClaimInfo(
      countHarvest,
      +term,
      enteredAt,
      currentRewards,
      +claimShedule.variableRewardTaken,
    );

    // CURRENLY IN DAYS
    return data.msToNextClaim > 0 ? data.msToNextClaim / 1000 / 60 / 60 / 24 : 0;
  }, [countHarvest, term, enteredAt]);

  const isCanClaim = useMemo(
    () => nextClaimInfo(
      countHarvest,
      +term,
      enteredAt,
      currentRewards,
      +claimShedule.variableRewardTaken,
    ).canClaim,
    [countHarvest, term, enteredAt],
  );

  const lockUpEndTimestamp = useMemo(
    () => +enteredAt + (+term + 1) * MS_IN_YEAR,
    [term, enteredAt],
  );

  const lockUpIsOver = useMemo(() => Date.now() >= lockUpEndTimestamp, [lockUpEndTimestamp]);

  const mainCurrentRewardAmount = useMemo(
    () => +currentRewards[`variablePart${rewardTokenSymbol}`],
    [rewardTokenSymbol, currentRewards],
  );

  const secondaryCurrentRewardAmount = useMemo(
    () => +currentRewards[`variablePart${secondaryRewardTokenSymbol[rewardTokenSymbol]}`],
    [rewardTokenSymbol, currentRewards],
  );

  const toogleIsCardOpen = useCallback(() => {
    setIsCardOpen(!isCardOpen);
  }, [isCardOpen]);

  const handleOpenInfoModal = useCallback(() => {
    setIsInfoModalOpen(true);
  }, [setIsInfoModalOpen]);

  const handleCloseInfoModal = useCallback(() => {
    setIsInfoModalOpen(false);
  }, [setIsInfoModalOpen]);

  const handleOpenWithdrawModal = useCallback(() => {
    setIsWithdrawModalOpen(true);
  }, [setIsInfoModalOpen]);

  const handleCloseWithdrawModal = useCallback(() => {
    setIsWithdrawModalOpen(false);
  }, [setIsInfoModalOpen]);

  const handleWithdraw = useCallback(() => {
    if (!lockUpIsOver) {
      handleOpenWithdrawModal();
      return;
    }

    onWithdraw();
  }, [lockUpIsOver]);

  return (
    <Box className={clsx(classes.root, className)}>
      {isInfoModalOpen && (
        <InfoModal
          customTitle="Conditions for withdrawing tokens"
          text={(
            <Box>
              <Box className={classes.withdrawalParagpaph}>
                Tokens can be withdrawn without penalty only after the end of the staking period.
              </Box>
              <Box className={classes.withdrawalParagpaph}>
                If the withdrawal of the token happens before the end of the Staking period,
                you will receive a penalty of 50% Total fixed reward for the entire
                planned blocking period.
              </Box>
              <Box className={classes.withdrawalParagpaph}>
                The withdrawal amount = Deposit + Current fixed reward - Total fixed reward/2
              </Box>
              <Box className={classes.withdrawalParagpaph}>
                NOTE: You forfeit all unpaid Variable reward
              </Box>
            </Box>
        )}
          open={isInfoModalOpen}
          onClose={handleCloseInfoModal}
        />
      )}
      {isWithdrawModalOpen && (
        <WithdrawModal
          open={isWithdrawModalOpen}
          onClose={handleCloseWithdrawModal}
          onWithdraw={onWithdraw}
          fixedPenalty={getFixedReward(+deposit, +term) / 2}
          currentRewards={currentRewards}
        />
      )}
      <Box className={clsx(classes.mainInfoContainer)}>
        <Box>
          <Typography
            variant="body1"
            className={clsx(classes.onlyTablet, 'articleSmall sans grey')}
          >
            Term
          </Typography>
          <Typography className="l sans">
            {+term + 1} year{+term > 0 ? 's' : ''}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            className={clsx(classes.onlyTablet, 'articleSmall sans grey')}
          >
            Total fixed reward
          </Typography>
          <Typography className="l sans">
            {formatNumber(getFixedReward(+deposit, +term))} KON
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            className={clsx(classes.onlyTablet, 'articleSmall sans grey')}
          >
            Final return
          </Typography>
          <Typography className="l sans">{formatNumber(+finalReturn)} KON</Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            className={clsx(classes.onlyTablet, 'articleSmall sans grey')}
          >
            Current fixed reward
          </Typography>
          <Typography className="l sans">
            {formatNumber(+currentRewards.fixedAmount)} KON
          </Typography>
        </Box>
        {(!isCardOpen || width > 1215) && (
          <Box className={clsx(classes.buttonsContainer)}>
            <Button
              disabled={!isCanClaim}
              onClick={onClaim}
              color="secondary"
              className={clsx(classes.claimButton)}
            >
              Claim
            </Button>

            <IconButton
              aria-label="collapse card"
              className={clsx(classes.chevronButton, classes.onlyDesktop)}
              onClick={toogleIsCardOpen}
            >
              <ChevronDown />
            </IconButton>
          </Box>
        )}
      </Box>

      <Box
        className={clsx(classes.secondaryInfoContainer, {
          [classes.secondaryInfoContainerActive]: isCardOpen,
        })}
      >
        <Box>
          <Typography variant="body1" className="articleSmall sans grey">
            Deposit
          </Typography>
          <Typography variant="body1" className={clsx(classes.cellValue, 'sans')}>
            {formatNumber(+deposit)} <span>KON</span>
          </Typography>
          {!lockUpIsOver && (
            <Box className={classes.withdrawContainer}>
              <Button
                onClick={handleOpenInfoModal}
                variant="text"
                className={clsx(classes.warnButton, 'empty')}
              >
                <WarningIcon />
              </Button>
              <Typography variant="body1" className="articleSmall grey sans">
                {new Date(lockUpEndTimestamp).toLocaleDateString(undefined, dateConfig)}
              </Typography>
              <Button onClick={handleWithdraw} variant="text">
                <Typography variant="body2">Withdraw</Typography>
              </Button>
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="body1" className="articleSmall sans grey">
            Before the next claim
          </Typography>
          <Typography variant="body1" className={clsx(classes.cellValue, 'sans')}>
            {formatNumber(msToNextClaim)} <span className={classes.days}>days</span>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" className="articleSmall sans grey">
            Profitability in KON{' '}
          </Typography>
          <Typography variant="body1" className={clsx(classes.cellValue, 'sans')}>
            {formatNumber((+finalReturn / +deposit) * 100)}
            <span>%</span>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" className="articleSmall sans grey">
            Variable reward
          </Typography>
          <Typography variant="body1" className={clsx(classes.cellValue, 'sans accent')}>
            {formatNumber(mainCurrentRewardAmount)} <span>{rewardTokenSymbol}</span>
          </Typography>
          {secondaryCurrentRewardAmount > 0 && (
            <Typography variant="body1" className={clsx('articleSmall accent sans')}>
              and {formatNumber(secondaryCurrentRewardAmount)}{' '}
              <span>{secondaryRewardTokenSymbol[rewardTokenSymbol]}</span>
            </Typography>
          )}
        </Box>
        {lockUpIsOver ? (
          <Button
            className={clsx(classes.calculatorButton, classes.smallButton)}
            onClick={handleWithdraw}
          >
            Withdraw
          </Button>
        ) : (
          <Button
            className={clsx(classes.calculatorButton, classes.smallButton)}
            color="primary"
            variant="outlined"
            target="_blank"
            href={calculatorUrl}
          >
            Calculator
          </Button>
        )}
        <Button
          color="secondary"
          className={clsx(classes.claimButton, classes.calculatorButton, classes.onlyTablet)}
          disabled={!isCanClaim}
          onClick={onClaim}
        >
          Claim
        </Button>
      </Box>
      <Box className={clsx(classes.extraChevron, classes.onlyTablet)}>
        <IconButton className={clsx(classes.chevronButton)} onClick={toogleIsCardOpen}>
          <ChevronDown />
        </IconButton>
      </Box>
    </Box>
  );
};
