/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  root: {
  },
  content: {
    position: 'relative',
  },
  mintBlob: {
    top: 0,
    minWidth: 1000,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: -1,
  },
}));
