import stakeActionTypes from 'store/stakes/actionTypes';
import userActionTypes from 'store/user/actionTypes';
import { UIState } from 'types';
import { RequestStatus } from 'types/store';
import { getUIReducer } from '.';

const initialState: UIState = {
  [stakeActionTypes.GET_STAKES_DATA]: RequestStatus.INIT,
  [stakeActionTypes.APPROVE]: RequestStatus.INIT,
  [stakeActionTypes.STAKE]: RequestStatus.INIT,
  [stakeActionTypes.UNSTAKE]: RequestStatus.INIT,
  [userActionTypes.GET_TOKEN_BALANCE]: RequestStatus.INIT,
  [userActionTypes.GET_WHITELIST_FOR_USER]: RequestStatus.INIT,
};

const uiReducer = getUIReducer(initialState);

export default uiReducer;
