import React, { FC, ReactNode } from 'react';

import {
  Box, Dialog, Typography, IconButton, DialogProps,
} from '@material-ui/core';
import clsx from 'clsx';

import { CloseIcon } from 'theme/icons';
import { useStyles } from './Modal.styles';

export interface ModalProps extends DialogProps {
  customTitle?: string | ReactNode;
  open: boolean;
  onClose: () => void;
  className?: string;
  headerClassName?: string;
}

export const Modal: FC<ModalProps> = (props) => {
  const {
    customTitle = ' ',
    open,
    className,
    children,
    onClose,
    headerClassName,
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      transitionDuration={{
        enter: 200,
        exit: 0,
      }}
      className={clsx(classes.root, className)}
      open={open}
      onClose={onClose}
      {...props}
    >
      <Box className={clsx(classes.modalHeader, headerClassName)}>
        {customTitle && (typeof customTitle === 'string' ? <Typography className={classes.modalTitle} variant="h2">{customTitle}</Typography> : customTitle)}
        <IconButton color="secondary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Dialog>
  );
};
