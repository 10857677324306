/* eslint-disable max-len */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { isMainnet } from 'config/constants';
import { Chains } from 'types';
import { updateWhitelistInfo } from 'store/user/reducer';
import { convertTimestampToMs, fromDecimals } from 'utils';
import actionTypes from '../actionTypes';
import { getWhitelistForUser } from '../actions';

export function* getWhitelistForUserSaga({
  type,
  payload: { web3Provider, address },
}: ReturnType<typeof getWhitelistForUser>) {
  yield put(apiActions.request(type));
  const { abi: tokenAbi, address: stakingAddress } =
    contractsConfig.contracts[ContractsNames.staking][isMainnet ? 'mainnet' : 'testnet'];

  try {
    const stakingContract = yield new web3Provider.eth.Contract(tokenAbi, stakingAddress[Chains.binance]);
    const {
      amount, enteredAt, lockUpWL,
    } = yield call(stakingContract.methods.whiteListForUser(address).call);

    yield put(updateWhitelistInfo({
      amount: fromDecimals(amount), enteredAt: convertTimestampToMs(enteredAt), lockUpWL,
    }));

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_WHITELIST_FOR_USER, getWhitelistForUserSaga);
}
