import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { CSSProperties } from '@material-ui/styles/withStyles';
import {
  COLOR_ACCENT, COLOR_ACCENT_DARKEN,
  COLOR_MINT_200,
  COLOR_MINT_700, COLOR_MINT_600, COLOR_WHITE,
  COLOR_GREY_2,
  COLOR_BLACK,
} from 'theme/colors';

export const getMuiButton = (theme: Theme): Overrides['MuiButton'] => {
  const outlinedCommon: CSSProperties = {
    color: COLOR_WHITE,
    borderRadius: '52px',
    border: `1px solid ${COLOR_ACCENT}`,
    background: 'transparent',
    transition: '300ms ease-out',
    textTransform: 'capitalize',
    height: '45px',
    padding: theme.spacing(1.5, 2.5),
    ...theme.typography.button,

    '& $endIcon': {
      '& > *': {
        '& > *': {
          fill: COLOR_MINT_600,
        },
      },
    },

    '&$sizeLarge': {
      padding: theme.spacing(2, 7),
      height: '65px',
      fontSize: 24,
    },

    '&:hover': {
      background: COLOR_ACCENT,
      '& $endIcon': {
        '& > *': {
          '& > *': {
            fill: COLOR_ACCENT,
          },
        },
      },
    },
    '&:active': {
      background: COLOR_ACCENT,
    },
    '&$disabled': {
      opacity: 0.55,
    },
  };

  return {
    outlined: {
      ...outlinedCommon,
    },
    outlinedPrimary: {
      ...outlinedCommon,
      backgroundColor: 'transparent',
      color: COLOR_GREY_2,
      borderColor: COLOR_GREY_2,
      '&:hover': {
        color: COLOR_BLACK,
        backgroundColor: COLOR_GREY_2,
        borderColor: COLOR_GREY_2,
      },
      '&:active': {
        background: COLOR_GREY_2,
        borderColor: COLOR_GREY_2,
        '& $endIcon': {
          '& > *': {
            '& > *': {
              fill: COLOR_GREY_2,
            },
          },
        },
      },
    },
    outlinedSecondary: {
      ...outlinedCommon,
      backgroundColor: COLOR_ACCENT,
      '&:hover, &:active': {
        backgroundColor: COLOR_ACCENT_DARKEN,
        borderColor: COLOR_ACCENT_DARKEN,
      },
    },
    text: {
      background: 'none',
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderColor: 'transparent',
      color: COLOR_ACCENT,
      textDecoration: 'underline',
      padding: theme.spacing(0, 0.5),
      '&:active': {
        color: COLOR_MINT_700,
        background: COLOR_ACCENT,
      },
      '&.empty': {
        padding: 0,
        minWidth: 0,
        background: 'transparent !important',
      },
    },

    contained: {
      ...outlinedCommon,
      backgroundColor: `${COLOR_MINT_700} !important`,
      borderWidth: '0 !important',
      boxShadow: 'none',
      color: `${COLOR_MINT_200} !important`,
      '&:active, &:hover': {
        color: COLOR_MINT_700,
        background: 'initial',
        boxShadow: 'none',
      },
    },
    startIcon: {
      marginRight: theme.spacing(1.5),
      marginLeft: 0,
    },
    endIcon: {
      marginLeft: theme.spacing(1.5),
      marginRight: 0,
    },
  };
};

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'outlined',
});
