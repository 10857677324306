import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(4),
    display: 'grid',
    gap: 16,
  },

  header: {
    padding: theme.spacing(0, 4),
    display: 'grid',
    gap: 10,
    gridTemplateColumns: '1fr 1fr 1fr 1fr 220px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
