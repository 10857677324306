import { fork } from 'redux-saga/effects';

import getStakesData from './getStakesData';
import approveSaga from './approveSaga';
import stakeSaga from './stake';
import unstakeSaga from './unstake';
import claimSaga from './claim';

export default function* stakesSagas() {
  yield fork(getStakesData);
  yield fork(approveSaga);
  yield fork(stakeSaga);
  yield fork(unstakeSaga);
  yield fork(claimSaga);
}
