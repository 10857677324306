import React, {
  VFC, useEffect, useMemo,
} from 'react';
import {
  Container, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import stakesSelector from 'store/stakes/selectors';
import {
  IStake, RequestStatus, RewardTokenSymbol, State,
} from 'types';
import uiSelector from 'store/ui/selectors';
import actionTypesStakes from 'store/stakes/actionTypes';
import { useDispatch } from 'react-redux';
import { StakeCards } from 'containers';
import { requestStakesData } from 'store/stakes/actions';
import { useWalletConnectorContext } from 'services';
import { updateStakes } from 'store/stakes/reducer';
import { useStyles } from './StakesPage.styles';

export const StakesPage: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const address = useShallowSelector<State, string>(userSelector.getProp('address'));
  const stakes = useShallowSelector<State, IStake[]>(stakesSelector.getProp('stakes'));
  const rewardTokenSymbol = useShallowSelector<State, RewardTokenSymbol>(stakesSelector.getProp('rewardTokenSymbol'));

  const {
    [actionTypesStakes.GET_STAKES_DATA]: stakesRequestStatus,
  } = useShallowSelector(uiSelector.getUI);

  const isStakesLoading = useMemo(
    () => [stakesRequestStatus].includes(RequestStatus.REQUEST),
    [stakesRequestStatus],
  );

  // const handleClickOnStake = useCallback(() => {
  //   if (!address) {
  //     dispatch(updateWalletConnectModal({ isOpen: true }));
  //     return;
  //   }
  //   navigate(routes.stake.root);
  // }, [address]);

  useEffect(() => {
    if(!address) {
      dispatch(updateStakes([]));
      return;
    }

    dispatch(requestStakesData({ web3Provider: walletService.Web3() }));
  }, [address, walletService]);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        Staking
      </Typography>
      <Typography variant="body1" className={clsx(classes.subtitle)}>
        An All-In-One Solution Allowing Projects To Get Connected With Retail Investors
      </Typography>
      {/* {stakes.length > 0 || isStakesLoading ? (
        <Button onClick={handleClickOnStake} color="secondary" className={classes.addStake}>
          Add new stake
        </Button>
      ) : (
        <Box className={classes.section}>
          <Button onClick={handleClickOnStake} size="large" color="secondary">
            Stake
          </Button>
        </Box>
      )} */}
      <StakeCards
        isStakesLoading={isStakesLoading}
        stakes={stakes}
        rewardTokenSymbol={rewardTokenSymbol}
      />
    </Container>
  );
};
