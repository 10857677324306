export const multiplierPerYear = {
  0: 0.15,
  1: 0.35,
  2: 0.6,
};

// eslint-disable-next-line arrow-body-style
export const getFixedReward = (deposit: number, years: number) => {
  return deposit * multiplierPerYear[years];
};
