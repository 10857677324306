export const COLOR_ERROR = '#FF511B';
export const COLOR_BLACK = '#000000';
export const COLOR_CAPTION = '#AAAAAA';

export const COLOR_BG_DARK_7 = '#180134';
export const COLOR_BG_DARK_5 = 'rgba(27, 33, 33, 0.5)';
export const COLOR_BG_DARK_8 = 'rgba(27, 33, 33, 0.8)';

export const COLOR_MINT_100 = '#DDF3EC';
export const COLOR_MINT_200 = '#CFFFF1';
export const COLOR_MINT_300 = '#A4F8DE';
export const COLOR_MINT_400 = '#EA33F7';
export const COLOR_MINT_500 = '#446B5F';
export const COLOR_MINT_600 = '#324F46';
export const COLOR_MINT_700 = '#283732';
export const COLOR_MINT_800 = '#1B2121';
export const COLOR_MINT_900 = '#283732';

export const COLOR_MINT_400_BOX_SHADOW = '0px 6px 12px rgba(139, 219, 195, 0.14)';

// NEW COLORS
// TODO: DELETE NOT NEED COLORS WHEN FINISH PROJECT
export const COLOR_CARD_BG = 'rgba(20, 20, 44, 0.5)';
export const COLOR_DARL = '#170034';
export const COLOR_BORDER = '#3C3D54';
export const COLOR_WHITE = '#EDEAF4';
export const COLOR_WHITE_SEMI_65 = 'rgba(237, 234, 244, 0.65)';

export const COLOR_ACCENT = '#EA33F7';
export const COLOR_ACCENT_DARKEN = '#AF28B9';

export const COLOR_GREY_2 = '#F2F2F2';
export const COLOR_GREY_SEMI_40 = 'rgba(16, 13, 30, 0.4)';

export const COLOR_DARK_SEMI_41 = 'rgba(36, 36, 36, 0.41)';
