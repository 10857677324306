import { ConnectWalletModal } from 'components/ConnectWalletModal';
import { useShallowSelector } from 'hooks';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useWalletConnectorContext } from 'services';
import { updateWalletConnectModal } from 'store/walletConnectModal/reducer';
import walletConnectModalSelector from 'store/walletConnectModal/selectors';
import { Chains, State, WalletProviders } from 'types';

const Modals: FC = () => {
  const dispatch = useDispatch();

  const { connect } = useWalletConnectorContext();

  const isWalletConnectModalOpen = useShallowSelector<State, boolean>(
    walletConnectModalSelector.getProp('isOpen'),
  );

  const handleCloseConnectWalletModal = useCallback(() => {
    dispatch(updateWalletConnectModal({ isOpen: false }));
  }, []);

  const handleConnectWallet = useCallback(async () => {
    await connect(WalletProviders.metamask, Chains.binance);
    handleCloseConnectWalletModal();
  }, []);

  return (
    <>
      {isWalletConnectModalOpen && (
        <ConnectWalletModal
          onClose={handleCloseConnectWalletModal}
          open={isWalletConnectModalOpen}
          connectWallet={handleConnectWallet}
        />
      )}
    </>
  );
};

export default Modals;
