import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_CARD_BG, COLOR_BORDER, COLOR_GREY_SEMI_40,
} from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(5, 1),
  },

  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },

  amount: {
    marginBottom: theme.spacing(1.5),
    display: 'block',
  },

  subtitle: {
    margin: theme.spacing(0, 'auto'),
    maxWidth: 468,
    textAlign: 'center',
  },

  section: {
    background: COLOR_CARD_BG,
    borderRadius: 24,
    border: `1px solid ${COLOR_BORDER}`,
    padding: theme.spacing(3, 4),
    margin: theme.spacing(3, 'auto'),
    maxWidth: 552,
  },

  info: {
    borderRadius: 14,
    padding: theme.spacing(3, 1),
    background: COLOR_GREY_SEMI_40,
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  checkboxes: {
    width: '100%',
    margin: theme.spacing(5, 0, 3),
  },
  checkboxesInner: {
    ...flexHelper('space-around'),
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridGap: 10,
  },
  checkboxLabel: {
    margin: 0,
  },
  checkboxButton: {
    marginRight: theme.spacing(1.5),
  },

  reward: {
    opacity: 0.5,
    marginBottom: theme.spacing(1),
  },
  rewardValue: {
    fontSize: 32,
    letterSpacing: '-0.02em',
    span: {
      fontSize: 25,
    },
  },
  percent: {
    opacity: 0.4,
  },

  center: {
    ...flexHelper(),
  },

  whitelist: {
    marginBottom: theme.spacing(3),
  },

  warning: {
    marginTop: theme.spacing(2),
  },

  warningAboutWl: {
    margin: theme.spacing(2, 0),
  },
}));
