import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const WarningIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="17" height="16" fill="none" viewBox="0 0 17 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM9.5 12C9.5 12.5523 9.05229 13 8.5 13C7.94772 13 7.5 12.5523 7.5 12C7.5 11.4477 7.94772 11 8.5 11C9.05229 11 9.5 11.4477 9.5 12ZM8.5 3C7.94772 3 7.5 3.44772 7.5 4V8C7.5 8.55228 7.94772 9 8.5 9C9.05229 9 9.5 8.55228 9.5 8V4C9.5 3.44772 9.05229 3 8.5 3Z"
      fill="#A6A1AD"
    />
  </BaseSVGIcon>
);
