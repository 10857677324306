import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { flexHelper } from 'utils';
import { COLOR_WHITE } from '../../theme/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    ...flexHelper(),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  headerContainer: {
    width: '100%',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${COLOR_WHITE}`,
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  },
  header: {
    ...flexHelper('space-between'),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headerLogo: {
    maxWidth: 203,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2.5),
    },
  },
  headerText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerButton: {
    width: 200,
  },
  address: {
    '& > span': {
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));
