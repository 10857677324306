import React, { VFC } from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { Skeleton } from '@material-ui/lab';
import { useStyles } from './StakeCardSkeleton.styles';

export interface StakeCardSkeletonProps {
  className?: string;
}

export const StakeCardSkeleton: VFC<StakeCardSkeletonProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)}>
      <Skeleton height={32} />
      <Skeleton height={32} />
      <Skeleton height={32} />
      <Skeleton height={32} />
      <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="10px">
        <Skeleton height={45} width={156} />
        <Skeleton />
      </Box>
    </Box>
  );
};
