/* eslint-disable @typescript-eslint/no-explicit-any */
import { INetwork, IProvider, ISettings } from '@amfi/connect-wallet/dist/interface';
import { DialogProps } from '@material-ui/core';

export interface OptionalClassNameProp {
  className?: string;
}
export interface IConnectWallet {
  network: INetwork;
  provider: {
    [index: string]: IProvider;
  };
  settings: ISettings;
}
export interface IChainConfig {
  name: string;
  id: number;
  rpc: string;
  tx: {
    link: string;
  };
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExp: string;
}

export interface IContracts {
  decimals: number;
  names: string[];
  contracts: {
    [index: string]: {
      mainnet: {
        address?: {
          [key in Chains]: string
        };
        abi: any[];
      };
      testnet: {
        address?: {
          [key in Chains]: string
        };
        abi: any[];
      };
    };
  };
}

// eslint-disable-next-line no-shadow
export enum WalletProviders {
  metamask = 'MetaMask',
}

// eslint-disable-next-line no-shadow
export enum Chains {
  binance = 'Binance',
}

export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;

export * from './store';

export type NativeModalProps = DialogProps;

// eslint-disable-next-line no-shadow
export enum Status {
  active = 'active',
  upcoming = 'upcoming',
  completed = 'completed',
}

// eslint-disable-next-line no-shadow
export enum RoundingModes {
  up,
  down
}
