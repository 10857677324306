import ui from './ui/reducer';
import user from './user/reducer';
import stakes from './stakes/reducer';
import walletConnectModal from './walletConnectModal/reducer';

export default {
  ui,
  user,
  stakes,
  walletConnectModal,
};
