/* eslint-disable max-len */
import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { isMainnet } from 'config/constants';
import userSelector from 'store/user/selectors';
import { unstake } from 'store/stakes/actions';
import actionTypes from 'store/stakes/actionTypes';
import { Chains } from 'types';
import { toast } from 'react-toastify';
import { ReactText } from 'react';
import { estimateGasForMethod } from 'utils';
import { StakingAbi } from '../../../contract-types/StakingAbi';
import { getStakesDataSaga } from './getStakesData';

function* claimSaga({ type, payload: { web3Provider, stakeIndex } }: ReturnType<typeof unstake>) {
  yield put(apiActions.request(type));
  const { abi: stakingAbi, address: stakingAddress } =
    contractsConfig.contracts[ContractsNames.staking][isMainnet ? 'mainnet' : 'testnet'];

  const myAddress = yield select(userSelector.getProp('address'));

  let toastId: ReactText;
  try {
    const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
      stakingAbi,
      stakingAddress[Chains.binance],
    );

    toastId = toast.loading('Claim in process...');

    const opts = yield call(estimateGasForMethod, {
      web3Provider,
      contract: stakingContract,
      method: 'harvest',
      txOpts: { from: myAddress },
      methodArgs: [stakeIndex],
    });

    yield call(stakingContract.methods.harvest(stakeIndex).send, {
      from: myAddress,
      gas: opts.estimatedGas,
      gasPrice: opts.gasPrice,
    });

    toast.update(toastId, {
      render: 'Tokens are claimed!',
      type: 'success',
      isLoading: false,
      closeOnClick: true,
    });

    yield call(getStakesDataSaga, {
      type: actionTypes.GET_STAKES_DATA,
      payload: {
        web3Provider,
      },
    });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    toast.update(toastId, {
      render: 'Something went wrong!',
      type: 'error',
      isLoading: false,
      closeOnClick: true,
    });
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.CLAIM, claimSaga);
}
