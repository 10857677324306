import {
  select, put, takeLatest, call,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { isMainnet } from 'config/constants';
import { estimateGasForMethod, toDecimals } from 'utils';
import { Chains } from 'types';
import { Erc20Abi } from 'contract-types';
import { approve } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';

export function* approveSaga({
  type,
  payload: { web3Provider, spender, amount },
}: ReturnType<typeof approve>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));

  const { abi: tokenAbi, address: tokenAddress } =
    contractsConfig.contracts[ContractsNames.token][isMainnet ? 'mainnet' : 'testnet'];

  const amountWithDecimals = toDecimals(amount);

  try {
    const tokenContract: Erc20Abi = yield new web3Provider.eth.Contract(
      tokenAbi,
      tokenAddress[Chains.binance],
    );

    const opts = yield call(estimateGasForMethod, {
      web3Provider,
      contract: tokenContract,
      method: 'approve',
      txOpts: { from: myAddress },
      methodArgs: [spender, amountWithDecimals],
    });
    yield call(tokenContract.methods.approve(spender, amountWithDecimals).send, {
      from: myAddress,
      gas: opts.estimatedGas,
      gasPrice: opts.gasPrice,
    });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    throw new Error('User rejected transaction');
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.APPROVE, approveSaga);
}
