/* eslint-disable max-len */
import { contractsConfig, ContractsNames } from 'config';
import {
  put, call, takeLatest, all, select,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { isMainnet } from 'config/constants';
import userSelector from 'store/user/selectors';
import { Chains, IStake } from 'types';
import { convertTimestampToMs, fromDecimals } from 'utils';
import { StakingAbi } from 'contract-types';
import actionTypes from '../actionTypes';
import { updateRewardTokenSymbol, updateStakes } from '../reducer';
import { requestStakesData } from '../actions';

export function* getStakesDataSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof requestStakesData>) {
  yield put(apiActions.request(type));

  const myAddress = yield select(userSelector.getProp('address'));

  const { address: konAddress } =
    contractsConfig.contracts[ContractsNames.token][isMainnet ? 'mainnet' : 'testnet'];
  const { abi: stakingAbi, address: stakingAddress } =
    contractsConfig.contracts[ContractsNames.staking][isMainnet ? 'mainnet' : 'testnet'];

  const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(
    stakingAbi,
    stakingAddress[Chains.binance],
  );

  try {
    const rewardTokenAddress: string = yield call(stakingContract.methods.rewardToken().call);
    const rewardTokenSymbol =
      rewardTokenAddress.toLowerCase() === konAddress[Chains.binance].toLowerCase()
        ? 'KON'
        : 'USDT';

    yield put(updateRewardTokenSymbol(rewardTokenSymbol));

    const userStakesIndexes: Array<string> = yield call(
      stakingContract.methods.indexes(myAddress).call,
    );
    const baseStakesInfoPromises = userStakesIndexes.map(
      (stakeIndex) => stakingContract.methods.allDeposits(stakeIndex).call(),
    );
    const fixedRewardStakesInfoPromises = userStakesIndexes.map(
      (stakeIndex) => stakingContract.methods.currentFixedPart(stakeIndex).call(),
    );
    const currentVariableRewardsInfoPromises = userStakesIndexes.map(
      (stakeIndex) => stakingContract.methods.varPart(stakeIndex).call(),
    );
    const rewardsAfter3YearStakesInfoPromises = userStakesIndexes.map(
      (stakeIndex) => stakingContract.methods.reward3Info(stakeIndex).call(),
    );

    const baseStakesInfo = yield all(baseStakesInfoPromises);
    const fixedRewardStakesInfo = yield all(fixedRewardStakesInfoPromises);
    const rewardsAfter3YearStakesInfo = yield all(rewardsAfter3YearStakesInfoPromises);
    const currentVariableRewardsInfo = yield all(currentVariableRewardsInfoPromises);

    const finalStakesData: Array<IStake> = [];

    for (let i = 0; i < userStakesIndexes.length; i += 1) {
      const stakeInfo = {
        stakeIndex: userStakesIndexes[i],
        term: baseStakesInfo[i].lockUp,
        enteredAt: convertTimestampToMs(baseStakesInfo[i].enteredAt),
        deposit: fromDecimals(baseStakesInfo[i].sumInLock),
        currentRewards: {
          fixedAmount: fromDecimals(fixedRewardStakesInfo[i]),
          variablePartKON: fromDecimals(currentVariableRewardsInfo[i].inKON),
          variablePartUSDT: fromDecimals(currentVariableRewardsInfo[i].inUSDT),
          gotFixed: baseStakesInfo[i].gotFixed,
        },
        claimShedule: {
          pool: baseStakesInfo[i].pool,
          part: rewardsAfter3YearStakesInfo[i].part,
          variableRewardTaken: rewardsAfter3YearStakesInfo[i].variableRewardTaken,
        },
        receivedRewardKON: fromDecimals(baseStakesInfo[i].varKoeff[2]),
        receivedRewardUSDT: fromDecimals(baseStakesInfo[i].varKoeff[3]),
        countHarvest: baseStakesInfo[i].countHarvest,
      };
      finalStakesData.push(stakeInfo);
    }

    yield put(updateStakes(finalStakesData.filter((stake) => +stake.deposit > 0).reverse()));
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_STAKES_DATA, getStakesDataSaga);
}
