/* eslint-disable max-len */
import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { isMainnet } from 'config/constants';
import userSelector from 'store/user/selectors';
import { unstake } from 'store/stakes/actions';
import actionTypes from 'store/stakes/actionTypes';
import { Chains } from 'types';
import { toast } from 'react-toastify';
import { ReactText } from 'react';
import { StakingAbi } from 'contract-types';
import { convertTimestampToMs, estimateGasForMethod } from 'utils';
import { getStakesDataSaga } from './getStakesData';

function* unstakeSaga({
  type, payload: {
    web3Provider,
    stakeIndex,
  },
}: ReturnType<typeof unstake>) {
  yield put(apiActions.request(type));
  const {
    abi: stakingAbi,
    address: stakingAddress,
  } = contractsConfig.contracts[ContractsNames.staking][isMainnet ? 'mainnet' : 'testnet'];

  const myAddress = yield select(userSelector.getProp('address'));

  let toastId: ReactText;
  try {
    const stakingContract: StakingAbi = yield (new web3Provider.eth.Contract(stakingAbi, stakingAddress[Chains.binance]));
    const lockPeriodSettedByOwner = yield call(stakingContract.methods.lockOwnersDeposits(stakeIndex).call);
    const lockPeriodSettedByOwnerInMs = +convertTimestampToMs(lockPeriodSettedByOwner);
    // checking if users take locked by owner to certain date
    if(lockPeriodSettedByOwnerInMs > Date.now()) {
      toast.error(`You can't withdraw your tokens before ${new Date(lockPeriodSettedByOwnerInMs).toLocaleDateString(
        undefined, { year: 'numeric', month: 'numeric', day: 'numeric' },
      )}`);
      return;
    }

    toastId = toast.loading('Withdrawing your tokens...');

    const opts = yield call(estimateGasForMethod, {
      web3Provider,
      contract: stakingContract,
      method: 'withdraw',
      txOpts: { from: myAddress },
      methodArgs: [stakeIndex],
    });

    yield call(stakingContract.methods.withdraw(stakeIndex).send, {
      from: myAddress,
      gas: opts.estimatedGas,
      gasPrice: opts.gasPrice,
    });

    toast.update(toastId, {
      render: 'Tokens are withdrawn!',
      type: 'success',
      isLoading: false,
      closeOnClick: true,
    });

    yield call(getStakesDataSaga, {
      type: actionTypes.GET_STAKES_DATA,
      payload: {
        web3Provider,
      },
    });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    toast.update(toastId, {
      render: 'Something went wrong!',
      type: 'error',
      isLoading: false,
      closeOnClick: true,
    });
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.UNSTAKE, unstakeSaga);
}
