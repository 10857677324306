import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const WarningBigIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="78" height="78" fill="none" viewBox="0 0 78 78" {...props}>
    <path
      d="M39 29.25V35.75M39 48.75H39.0325M16.4833 61.75H61.5166C66.5203 61.75 69.6477 56.3333 67.1458 52L44.6292 13C42.1273 8.66667 35.8727 8.66667 33.3708 13L10.8542 52C8.35231 56.3333 11.4796 61.75 16.4833 61.75Z"
      stroke="#EA33F7"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSVGIcon>
);
