import React from 'react';
import { routes } from 'appConstants';
import { Navigate, Route, Routes } from 'react-router-dom';
import { StakePage } from 'pages/StakePage';
import { StakesPage } from 'pages/StakesPage';

const RoutesContainer = () => (
  <Routes>
    <Route path={routes.root} element={<StakesPage />} />
    <Route path={routes.stake.root} element={<StakePage />} />
    <Route path="/" element={<Navigate replace to={routes.root} />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default RoutesContainer;
