import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStake, RewardTokenSymbol, StakesState } from 'types';

const initialState: StakesState = {
  stakes: [],
  rewardTokenSymbol: 'KON',
};

export const stakesReducer = createSlice({
  name: 'stakes',
  initialState,
  reducers: {
    updateStakes: (state, action: PayloadAction<Array<IStake>>) => ({
      ...state,
      stakes: [...action.payload],
    }),
    updateRewardTokenSymbol: (state, action: PayloadAction<RewardTokenSymbol>) => ({
      ...state,
      rewardTokenSymbol: action.payload,
    }),
  },
});

export const { updateStakes, updateRewardTokenSymbol } = stakesReducer.actions;

export default stakesReducer.reducer;
