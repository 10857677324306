import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BORDER, COLOR_CARD_BG, COLOR_WHITE } from 'theme/colors';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

interface IStakeCardStylesProps {
  isOpen: boolean;
}

export const useStyles = makeStyles<Theme, IStakeCardStylesProps>((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  const containerCommon = {
    display: 'grid',
    gap: 10,
    gridTemplateColumns: '1fr 1fr 1fr 1fr 220px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: 20,
      columnGap: 30,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  };

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {},
      background: COLOR_CARD_BG,
      border: `1px solid ${COLOR_BORDER}`,
      padding: theme.spacing(4, 4),
      borderRadius: 20,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 3, 1),
      },
    },

    mainInfoContainer: {
      ...containerCommon,
      alignItems: 'center',
      paddingBottom: ({ isOpen }) => (isOpen ? theme.spacing(6) : 0),
      borderBottom: ({ isOpen }) => (isOpen ? `1px solid ${COLOR_BORDER}` : 'none'),
    },
    secondaryInfoContainer: {
      ...containerCommon,
      paddingTop: 0,
      transition: 'max-height 1s cubic-bezier(0, 1, 0, 1), padding 0.2s ease',

      maxHeight: 0,
      overflow: 'hidden',
    },
    secondaryInfoContainerActive: {
      paddingTop: theme.spacing(2),
      maxHeight: '1000px',
      transition: 'max-height 1s ease-in-out, padding 0.2s ease',
    },
    withdrawContainer: {
      ...flexHelper('flex-start'),
    },
    buttonsContainer: {
      ...flexHelper(),
    },

    chevronButton: {
      transform: ({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)'),
    },
    extraChevron: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    claimButton: {
      flexGrow: 1,
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(0),
      },
    },
    calculatorButton: {
      width: '100%',
      alignSelf: 'center',
    },
    warnButton: {
      marginRight: theme.spacing(1),
    },

    smallButton: {
      maxWidth: 156,
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },

    cellValue: {
      fontSize: 28,
      '& span': {
        fontSize: 24,
      },
    },
    days: {
      fontSize: '14px !important',
      color: COLOR_WHITE,
      opacity: 0.7,
    },

    onlyTablet: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    onlyDesktop: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    withdrawalParagpaph: {
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
  });
});
