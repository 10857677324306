import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';

export const getMuiDialogProps = (theme: Theme): Overrides['MuiDialog'] => ({
  root: {
  },
  paper: {
    backgroundColor: '#fff',
    padding: theme.spacing(3, 3, 4, 3),
    borderRadius: '12px',
    minWidth: 450,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
});

export const getMuiDialogDefaultProps = (): ComponentsProps['MuiDialog'] => ({});
